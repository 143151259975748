import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalChallengeCard,
  GoalBreakdownChallengeCardMenu,
  GoalBreakdownChallengeType,
  useMoveGoalBreakdownChallenges,
} from 'features/goal';
import { useMoveGoalBreakdownChallengesMutation__NEW } from 'features/goal/hooks/mutation/useMoveGoalBreakdownChallenge__NEW';

import { Droppable } from 'shared/components/DragNDrop/Droppable';
import { Sortable } from 'shared/components/DragNDrop/Sortable';

type TGoalBreakdownBoardChallenges = {
  challenges: GoalBreakdownChallengeType[];
};

export const GoalBreakdownBoardChallenges__NEW = ({
  challenges,
}: TGoalBreakdownBoardChallenges) => {
  const { mutate: mutateChallenges } =
    useMoveGoalBreakdownChallengesMutation__NEW();
  const { moveToChallenges, moveToFocusArea } =
    useMoveGoalBreakdownChallenges();

  return (
    <Droppable
      acceptType={['challenge']}
      onDrop={({ id, index }) => {
        challenges.length === 0 &&
          mutateChallenges({
            customChallengeId: id,
            index,
          });
      }}
      onHover={(draggedItem: {
        id: string;
        focusAreaIndex: number;
        index: number;
      }) => challenges.length === 0 && moveToFocusArea(-1)(draggedItem)}
    >
      {challenges.map((challenge, challengeIndex) => (
        <Sortable
          key={`${challenge.customChallengeId}-${challengeIndex}`}
          acceptType={['challenge']}
          item={{
            type: 'challenge',
            id: challenge.customChallengeId,
            index: challengeIndex,
            focusAreaIndex: -1,
          }}
          onHover={moveToChallenges(-1)}
          onDrop={({ id, index }) => {
            mutateChallenges({
              customChallengeId: id,
              index,
            });
          }}
        >
          <GoalChallengeCard
            title={challenge.title}
            picture={challenge.picture}
            emoji={challenge.emoji}
            dimensions={challenge.dimensions}
          >
            <GoalBreakdownChallengeCardMenu challenge={challenge} />
          </GoalChallengeCard>
          <Spacer size="xsm" />
        </Sortable>
      ))}
    </Droppable>
  );
};
