import { useCallback } from 'react';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  usePlanningBacklogQueryActions,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning/hooks';
import { PlanningDraggableItem } from 'features/planning/types';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';

import {
  findIndexByProp,
  deleteItemMutative,
  insertItemMutative,
} from 'shared/utils/array';
import { throttle } from 'shared/utils/throttle';

export const useMoveActiveFinishedSprintChallenge = () => {
  const { getSprints, updateSprints, cancelSprintsQueries } =
    useSprintsQueryActions();
  const { getBacklog, updateBacklog, cancelBacklogQueries } =
    usePlanningBacklogQueryActions();
  const { getDraftSprints, cancelDraftSprintsQueries, updateDraftSprints } =
    usePlanningDraftSprintsQueryActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveItemInList = useCallback(
    throttle(
      async (
        draggedItem: PlanningDraggableItem,
        hoveredItem: PlanningDraggableItem
      ) => {
        await cancelSprintsQueries();

        const sprints = getSprints();

        if (!sprints) {
          return;
        }

        const { items: sprintItems } = sprints;

        const { columnIndex: draggedColumnIndex } = draggedItem;
        const { columnIndex: hoveredColumnIndex } = hoveredItem;
        const newItems = [...sprintItems];

        const hoveredChallengeIndex = findIndexByProp(
          sprintItems[hoveredColumnIndex].challenges,
          'challengeId',
          hoveredItem.id
        );

        draggedItem.index = hoveredChallengeIndex;

        let draggedChallengeIndex, draggedChallenge;

        switch (draggedItem.location) {
          case CHALLENGE_LOCATIONS.BACKLOG:
            await cancelBacklogQueries();

            const backlog = getBacklog();

            if (backlog) {
              const { items: backlogItems } = backlog;
              const newBacklogItems = [...backlogItems];

              draggedChallengeIndex = findIndexByProp(
                backlogItems,
                'challengeId',
                draggedItem.id
              );
              draggedChallenge = deleteItemMutative(
                newBacklogItems,
                draggedChallengeIndex
              );

              updateBacklog(newBacklogItems);
            }
            break;
          case CHALLENGE_LOCATIONS.DRAFT_SPRINT:
            await cancelDraftSprintsQueries();

            const draftSprints = getDraftSprints();

            if (draftSprints) {
              const { items: draftSprintItems } = draftSprints;
              const newDraftSprintItems = [...draftSprintItems];

              draggedChallengeIndex = findIndexByProp(
                draftSprintItems[draggedColumnIndex].challenges,
                'challengeId',
                draggedItem.id
              );
              draggedChallenge = deleteItemMutative(
                newDraftSprintItems[draggedColumnIndex].challenges,
                draggedChallengeIndex
              );

              updateDraftSprints(newDraftSprintItems);
            }
            break;
          case CHALLENGE_LOCATIONS.ACTIVE_SPRINT:
            draggedChallengeIndex = findIndexByProp(
              sprintItems[draggedColumnIndex].challenges,
              'challengeId',
              draggedItem.id
            );

            draggedChallenge = deleteItemMutative(
              newItems[draggedColumnIndex].challenges,
              draggedChallengeIndex
            );
            break;
          case CHALLENGE_LOCATIONS.FINISHED_SPRINT:
            draggedChallengeIndex = findIndexByProp(
              sprintItems[draggedColumnIndex].challenges,
              'challengeId',
              draggedItem.id
            );

            draggedChallenge =
              sprintItems[draggedColumnIndex].challenges[draggedChallengeIndex];
            break;
          default:
            throw new Error('Invalid location');
        }

        if (draggedChallenge) {
          insertItemMutative(
            newItems[hoveredColumnIndex].challenges,
            hoveredChallengeIndex,
            draggedChallenge
          );
        }

        draggedItem.columnIndex = hoveredColumnIndex;
        draggedItem.isColumnChanged =
          hoveredColumnIndex !== draggedColumnIndex ||
          draggedItem.location !== hoveredItem.location;
        draggedItem.underChallengeId =
          hoveredChallengeIndex === 0
            ? null
            : draggedChallengeIndex !== undefined &&
              hoveredChallengeIndex > draggedChallengeIndex
            ? hoveredItem.id
            : hoveredItem.underChallengeId;
        draggedItem.location = CHALLENGE_LOCATIONS.ACTIVE_SPRINT;

        updateSprints(newItems);
      },
      500
    ),
    [
      getBacklog,
      updateBacklog,
      cancelBacklogQueries,
      getSprints,
      updateSprints,
      cancelSprintsQueries,
      getDraftSprints,
      cancelDraftSprintsQueries,
    ]
  );

  return {
    moveItemInList,
  };
};
