import { useCallback } from 'react';

import { SPRINT_PLANNING_DND, useFeatureFlag } from 'featureFlags';
import { usePinnedColumnsContext } from 'shared';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBacklog,
  usePlanningDraftSprintsQuery,
  PlanningBoardWrapper,
  BOARD_COLUMN_WIDTH,
  PlanningDraftSprints,
  PlanningActiveFinishedSprints,
  PlanningSprintModel,
  IPlanningDraftSprint,
} from 'features/planning';
import { PlanningDraftSprints__NEW } from 'features/planning/components/Board/DraftSprints__NEW';
import { useSprintsQuery } from 'features/sprint/hooks';

type SprintsByPin<T> = {
  pinned: T[];
  unpinned: T[];
};

const DEFAULT_SPRINTS = { pinned: [], unpinned: [] };

export const PlanningBoard = () => {
  const isSprintPlanningDndEnabled = useFeatureFlag(SPRINT_PLANNING_DND);
  const { pinnedColumns } = usePinnedColumnsContext();

  const {
    data: draftSprints = DEFAULT_SPRINTS,
    isLoading: isDraftSprintsLoading,
  } = usePlanningDraftSprintsQuery<SprintsByPin<IPlanningDraftSprint>>({
    options: {
      select: useCallback(
        (sprints: IPlanningDraftSprint[]) =>
          mapPinnedUnpinnedSprints<IPlanningDraftSprint[]>(
            sprints,
            pinnedColumns
          ) as SprintsByPin<IPlanningDraftSprint>,
        [pinnedColumns]
      ),
    },
  });
  const { data: sprints = DEFAULT_SPRINTS, isLoading: areSprintsLoading } =
    useSprintsQuery({
      options: {
        select: useCallback(
          (sprints) =>
            mapPinnedUnpinnedSprints<PlanningSprintModel[]>(
              sprints.reverse(),
              pinnedColumns
            ) as SprintsByPin<PlanningSprintModel>,
          [pinnedColumns]
        ),
      },
    });

  if (isDraftSprintsLoading || areSprintsLoading) {
    return <Loader />;
  }

  return (
    <PlanningBoardWrapper>
      <Flex height="100%" gap="0.5rem">
        <PlanningBacklog />
        <PlanningActiveFinishedSprints sprints={sprints.pinned} />
        {isSprintPlanningDndEnabled ? (
          // eslint-disable-next-line
          <PlanningDraftSprints__NEW sprints={draftSprints.pinned} />
        ) : (
          <PlanningDraftSprints sprints={draftSprints.pinned} />
        )}
      </Flex>
      <Box
        style={{
          width: `calc(100% - ${BOARD_COLUMN_WIDTH})`,
          height: '100%',
          overflowX: 'auto',
        }}
      >
        <Flex height="100%" width="100%" gap="0.5rem">
          <PlanningActiveFinishedSprints sprints={sprints.unpinned} />
          {isSprintPlanningDndEnabled ? (
            // eslint-disable-next-line
            <PlanningDraftSprints__NEW sprints={draftSprints.unpinned} />
          ) : (
            <PlanningDraftSprints sprints={draftSprints.unpinned} />
          )}
        </Flex>
      </Box>
    </PlanningBoardWrapper>
  );
};

const mapPinnedUnpinnedSprints = <
  T extends Array<PlanningSprintModel | IPlanningDraftSprint>
>(
  sprints: T,
  pinnedColumns: Set<string>
) =>
  sprints.reduce(
    (
      acc: SprintsByPin<PlanningSprintModel | IPlanningDraftSprint>,
      sprint,
      index
    ) => {
      sprint.index = index;

      if (pinnedColumns.has(sprint.sprintId)) {
        acc.pinned.push(sprint);
      } else {
        acc.unpinned.push(sprint);
      }

      return acc;
    },
    {
      pinned: [],
      unpinned: [],
    }
  );
