import { memo, ReactNode, useMemo } from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import { TChallengeLocation } from 'features/challenge/config/types';
import { PlanningDraggableItem } from 'features/planning/types';

import { Sortable } from 'shared/components/DragNDrop/Sortable';

type PlanningBoardColumnItemProps = {
  type: string;
  id: string;
  columnIndex: number;
  index: number;
  children: ReactNode;
  isColumnChanged: boolean;
  fromSprintId?: string;
  location: TChallengeLocation;
  onDrop: (item: PlanningDraggableItem) => void;
  onHover: (
    draggedItem: PlanningDraggableItem,
    hoveredItem: PlanningDraggableItem
  ) => void;
  underChallengeId: Nullable<string>;
};

export const PlanningBoardColumnItem = memo(
  ({
    type,
    id,
    columnIndex,
    index,
    children,
    underChallengeId,
    onDrop,
    onHover,
    fromSprintId,
    location,
  }: PlanningBoardColumnItemProps) => {
    const item = useMemo(
      () => ({
        type,
        id,
        index,
        columnIndex,
        underChallengeId,
        isColumnChanged: false,
        fromBacklog: location === CHALLENGE_LOCATIONS.BACKLOG,
        toSprintId: undefined,
        fromSprintId,
        toBacklog: false,
        location,
      }),
      [fromSprintId, underChallengeId, columnIndex, id, index, type, location]
    );

    return (
      <>
        <Sortable
          acceptType={['challenge']}
          item={item}
          onHover={onHover}
          onDrop={async (item: PlanningDraggableItem) => {
            onDrop(item);
          }}
        >
          {children}
        </Sortable>
        <Spacer size="xsm" />
      </>
    );
  }
);
