import { When } from 'react-if';
import { For } from 'react-loops';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  INonRepeatedChallenge,
  IRepeatedChallenge,
} from 'features/challenge/config/types';
import {
  PlanningDraftSprintChallengeCard,
  IPlanningDraftSprint,
  PlanningBacklogChallengeCard,
  PlanningDraggableItem,
} from 'features/planning';
import { PlanningBoardColumnItem } from 'features/planning/components/Board/Column/Item';

type TPlanningBoardItems = {
  challenges: (INonRepeatedChallenge | IRepeatedChallenge)[];
  sprint?: IPlanningDraftSprint;
  sprintIndex?: number;
  onDrop: (item: PlanningDraggableItem) => void;
  onHover: (
    draggedItem: PlanningDraggableItem,
    hoveredItem: PlanningDraggableItem
  ) => void;
};

export const PlanningBoardItems__NEW = ({
  challenges,
  sprint,
  sprintIndex,
  onDrop,
  onHover,
}: TPlanningBoardItems) => {
  return (
    <When condition={challenges?.length}>
      <For of={challenges}>
        {(challenge, { index: challengeIndex }) => {
          const underChallengeId =
            challenges[challengeIndex - 1]?.challengeId || null;

          return (
            <PlanningBoardColumnItem
              key={`${challenge.challengeId}`}
              type="challenge"
              id={challenge.challengeId!}
              index={challengeIndex}
              columnIndex={sprintIndex ?? -1}
              isColumnChanged={false}
              onDrop={onDrop}
              onHover={onHover}
              fromSprintId={challenge.sprintId}
              underChallengeId={underChallengeId}
              location={challenge.location!}
            >
              <Spacer size="zr sm">
                <ChallengeCard challenge={challenge} sprint={sprint} />
              </Spacer>
            </PlanningBoardColumnItem>
          );
        }}
      </For>
    </When>
  );
};

const ChallengeCard = ({
  sprint,
  challenge,
}: {
  challenge: IRepeatedChallenge | INonRepeatedChallenge;
  sprint?: IPlanningDraftSprint;
}) => (
  <>
    {sprint ? (
      <PlanningDraftSprintChallengeCard challenge={challenge} sprint={sprint} />
    ) : (
      <PlanningBacklogChallengeCard challenge={challenge} />
    )}
  </>
);
