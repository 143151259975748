import { Flex } from '@chakra-ui/react';

import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBreakdownChallengeType,
  FocusAreaType,
  GoalBreakdownEmptyState,
} from 'features/goal';

import { BoardColumn } from 'shared/components/Board';

import { GoalBreakdownBoardChallenges__NEW } from './Challenges__NEW';
import { GoalBreakdownBoardFocusAreas__NEW } from './FocusAreas__NEW';

type TGoalBreakdownContent = {
  focusAreas: FocusAreaType[];
  challenges: GoalBreakdownChallengeType[];
};

export const GoalBreakdownContent__NEW = ({
  focusAreas,
  challenges,
}: TGoalBreakdownContent) => {
  const isGoalBreakdownFilled = challenges.length || focusAreas.length;

  return (
    <BoardColumn>
      {!isGoalBreakdownFilled ? (
        <GoalBreakdownEmptyState />
      ) : (
        <>
          <Box style={{ width: '100%', height: '100%' }}>
            <Spacer size="sm" height="100%">
              <Flex flexDirection="column" height="100%">
                {/* eslint-disable-next-line */}
                <GoalBreakdownBoardFocusAreas__NEW focusAreas={focusAreas} />
                <Flex grow={1} shrink={0} flexDirection="column">
                  {/* eslint-disable-next-line */}
                  <GoalBreakdownBoardChallenges__NEW challenges={challenges} />
                </Flex>
              </Flex>
            </Spacer>
          </Box>
        </>
      )}
    </BoardColumn>
  );
};
