export const ANALYTICS_EVENTS = {
  SESSION_RECAP_STARTED: 'session_recap_started',
  SESSION_RECAP_SENT: 'session_recap_sent',
  SESSION_RECAP_SCHEDULED: 'session_recap_scheduled',
  CHALLENGE_ADDED: 'challenge_added',
  CHALLENGE_ADDED_FROM_LIB: 'challenge_added_from_library',
  CHALLENGE_EDITED: 'challenge_edited',
  CHALLENGE_MARKED: 'challenge_marked',
  CHALLENGE_REMOVED: 'challenge_removed',
  CHAT_MESSAGE_SENT: 'chat_message_sent',
  CHAT_MESSAGE_SENT_NOW: 'chat_message_sent_now',
  CHAT_MESSAGE_EDITED: 'chat_message_edited',
  CHAT_MESSAGE_SCHEDULED: 'chat_message_scheduled',
  CHAT_MESSAGE_RESCHEDULED: 'chat_message_rescheduled',
  CHAT_OVERLAY_OPEN: 'chat_overlay_open',
  CHAT_REACTION_ADDED: 'chat_reaction_added',
  FREQUENCY_SELECTED: 'frequency_selected',
  HISTORY_SIDEBAR_OPEN: 'history_sidebar_open',
  NOTIFICATIONS_SIDEBAR_OPEN: 'notifications_sidebar_opened',
  NOTIFICATIONS_SIDEBAR_FILTER_APPLIED: 'notifications_sidebar_filter_applied',
  SPRINT_WITH_COACH_CHANGED: 'sprint_with_coach_changed',
  SPRINT_STARTED: 'sprint_started',
  SPRINT_RESTARTED: 'sprint_restarted',
  SPRINT_FINISHED: 'sprint_finished',
  SPRINT_REFLECTION_ANSWER_EDITED: 'sprint_reflection_answer_edited',
  TARGET_SELECTED: 'target_selected',

  NOTES_REPLY_ADDED: 'reply_added',
  NOTES_REPLY_EDITED: 'reply_edited',
  NOTES_REACTION_ADDED: 'note_reaction_added',
  NOTES_REPLY_REACTION_ADDED: 'reply_reaction_added',
  NOTES_LIST_FILTER_APPLIED: 'notes_list_filter_applied',
  OPEN_CHALLENGE_EDIT_FROM_NOTE: 'open_challenge_edit_from_note',

  ASSESSMENT_LAUNCH_SENT: 'assessment_launch_sent',
  NEW_ASSESSMENT_ADDED: 'new_assessment_added',
  FOCUS_AREA_CHANGED: 'focus_area_changed',
  LAST_ASSESSMENT_EDITED: 'last_assessment_edited',

  SESSION_CREATED: 'session_created',
  SESSION_DELETED: 'session_deleted',
  SESSION_ATTENDANCE_CHANGED: 'attendance_changed',
  LATE_OPTION_SELECTED: 'late_option_selected',
  SESSION_LENGTH_CHANGED: 'session_length_changed',
  NEXT_SESSION_DATE_EDITED: 'next_session_date_edited',
  NEXT_SESSION_NOTES_EDITED: 'next_session_notes_edited',
  PARTICIPANT_PROFILE_NOTES_EDITED: 'participant_profile_notes_edited',
  NOTE_DIMENSIONS_FIELD_EDITED: 'note_dimensions_field_edited',
  COACHING_TOOL_EDITED: 'coaching_tool_edited',
  COACHING_TOOL_DELETED: 'coaching_tool_deleted',
  RECAP_TEMPLATE_SELECTED: 'recap_template_selected',
  EDIT_PARTICIPANTS_NAME: 'edit_participants_name',

  PLANNING_CHALLENGE_ADDED: 'planning_challenge_added',
  PLANNING_CHALLENGE_EDITED: 'planning_challenge_edited',
  PLANNING_CHALLENGE_REMOVED: 'planning_challenge_removed',
  PLANNING_CHALLENGE_MOVED: 'planning_challenge_moved',
  PLANNING_CHALLENGE_ORDER_CHANGED: 'planning_challenge_order_changed',
  PLANNING_SPRINT_DRAFT_ADDED: 'planning_sprint_draft_added',
  PLANNING_SPRINT_DRAFT_DELETED: 'planning_sprint_draft_deleted',
  PLANNING_START_SPRINT: 'planning_start_sprint',
  PLANNING_VIEW_PAST_SPRINT_CLICKED: 'planning_view_past_sprint_clicked',
  PLANNING_VIEW_CURRENT_SPRINT_CLICKED: 'planning_view_current_sprint_clicked',
  FILTER_APPLIED: 'filter_applied',
} as const;

export type AnalyticsEvent =
  typeof ANALYTICS_EVENTS[keyof typeof ANALYTICS_EVENTS];

export const ANALYTICS_EVENTS_SOURCES = {
  PARTICIPANT_PAGE: 'participant_page',
  PARTICIPANTS_LIST: 'participants_list',
} as const;

export type AnalyticsEventSource =
  typeof ANALYTICS_EVENTS_SOURCES[keyof typeof ANALYTICS_EVENTS_SOURCES];
