import { useCallback } from 'react';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import { TChallengeLocation } from 'features/challenge/config/types';
import {
  usePlanningBacklogQueryActions,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning/hooks';
import { PlanningDraggableItem } from 'features/planning/types';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';

import {
  findIndexByProp,
  deleteItemMutative,
  insertItemMutative,
} from 'shared/utils/array';
import { throttle } from 'shared/utils/throttle';

export const useMoveSprintChallenge = () => {
  const { getDraftSprints, updateDraftSprints, cancelDraftSprintsQueries } =
    usePlanningDraftSprintsQueryActions();
  const { getBacklog, updateBacklog, cancelBacklogQueries } =
    usePlanningBacklogQueryActions();
  const { getSprints, cancelSprintsQueries } = useSprintsQueryActions();

  const moveItemToColumn = useCallback(
    (modifiedColumnIndex: number, location: TChallengeLocation) =>
      async (item: PlanningDraggableItem) => {
        item.underChallengeId = null;

        if (
          item.columnIndex === modifiedColumnIndex &&
          item.location === location
        ) {
          return;
        }

        await cancelDraftSprintsQueries();

        const draftSprints = getDraftSprints();

        if (!draftSprints) {
          return;
        }

        const { items } = draftSprints;
        const newItems = [...items];
        const { columnIndex } = item;
        let challengeIndex, challenge;

        switch (item.location) {
          case CHALLENGE_LOCATIONS.BACKLOG:
            await cancelBacklogQueries();

            const backlog = getBacklog();

            if (backlog) {
              const { items: backlogItems } = backlog;
              const newBacklogItems = [...backlogItems];

              challengeIndex = findIndexByProp(
                backlogItems,
                'challengeId',
                item.id
              );
              challenge = deleteItemMutative(newBacklogItems, challengeIndex);

              updateBacklog(newBacklogItems);
            }
            break;
          case CHALLENGE_LOCATIONS.DRAFT_SPRINT:
            challengeIndex = findIndexByProp(
              items[columnIndex].challenges,
              'challengeId',
              item.id
            );

            challenge = deleteItemMutative(
              newItems[columnIndex].challenges,
              challengeIndex
            );
            break;
          case CHALLENGE_LOCATIONS.ACTIVE_SPRINT:
          case CHALLENGE_LOCATIONS.FINISHED_SPRINT:
            await cancelSprintsQueries();

            const sprints = getSprints();

            if (sprints) {
              const { items: sprintItems } = sprints;

              challengeIndex = findIndexByProp(
                sprintItems[columnIndex].challenges,
                'challengeId',
                item.id
              );
              challenge = sprintItems[columnIndex].challenges[challengeIndex];
            }
            break;
        }

        item.columnIndex = modifiedColumnIndex;
        item.index = newItems[modifiedColumnIndex].challenges.length;
        item.isColumnChanged = true;
        item.location = CHALLENGE_LOCATIONS.DRAFT_SPRINT;

        if (challenge) {
          newItems[modifiedColumnIndex].challenges.push(challenge);
        }

        updateDraftSprints(newItems);
      },
    [
      getDraftSprints,
      getBacklog,
      updateDraftSprints,
      updateBacklog,
      cancelDraftSprintsQueries,
      cancelBacklogQueries,
      getSprints,
      cancelSprintsQueries,
    ]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveItemInList = useCallback(
    throttle(
      async (
        draggedItem: PlanningDraggableItem,
        hoveredItem: PlanningDraggableItem
      ) => {
        await cancelDraftSprintsQueries();

        const draftSprints = getDraftSprints();

        if (!draftSprints) {
          return;
        }

        const { items: draftSprintItems } = draftSprints;
        const { columnIndex: draggedColumnIndex } = draggedItem;
        const { columnIndex: hoveredColumnIndex } = hoveredItem;
        const newItems = [...draftSprintItems];

        const hoveredChallengeIndex = findIndexByProp(
          draftSprintItems[hoveredColumnIndex].challenges,
          'challengeId',
          hoveredItem.id
        );

        draggedItem.index = hoveredChallengeIndex;

        let draggedChallengeIndex, draggedChallenge;

        switch (draggedItem.location) {
          case CHALLENGE_LOCATIONS.BACKLOG:
            await cancelBacklogQueries();

            const backlog = getBacklog();

            if (backlog) {
              const { items: backlogItems } = backlog;
              const newBacklogItems = [...backlogItems];

              draggedChallengeIndex = findIndexByProp(
                backlogItems,
                'challengeId',
                draggedItem.id
              );
              draggedChallenge = deleteItemMutative(
                newBacklogItems,
                draggedChallengeIndex
              );

              updateBacklog(newBacklogItems);
            }
            break;
          case CHALLENGE_LOCATIONS.DRAFT_SPRINT:
            draggedChallengeIndex = findIndexByProp(
              draftSprintItems[draggedColumnIndex].challenges,
              'challengeId',
              draggedItem.id
            );

            draggedChallenge = deleteItemMutative(
              newItems[draggedColumnIndex].challenges,
              draggedChallengeIndex
            );
            break;
          case CHALLENGE_LOCATIONS.ACTIVE_SPRINT:
          case CHALLENGE_LOCATIONS.FINISHED_SPRINT:
            await cancelSprintsQueries();

            const sprints = getSprints();

            if (sprints) {
              const { items: sprintItems } = sprints;

              draggedChallengeIndex = findIndexByProp(
                sprintItems[draggedColumnIndex].challenges,
                'challengeId',
                draggedItem.id
              );
              draggedChallenge =
                sprintItems[draggedColumnIndex].challenges[
                  draggedChallengeIndex
                ];
            }
            break;
          default:
            throw new Error('Invalid location');
        }

        if (draggedChallenge) {
          insertItemMutative(
            newItems[hoveredColumnIndex].challenges,
            hoveredChallengeIndex,
            draggedChallenge
          );
        }

        draggedItem.columnIndex = hoveredColumnIndex;
        draggedItem.isColumnChanged =
          hoveredColumnIndex !== draggedColumnIndex ||
          draggedItem.location !== hoveredItem.location;
        draggedItem.underChallengeId =
          hoveredChallengeIndex === 0
            ? null
            : draggedChallengeIndex !== undefined &&
              hoveredChallengeIndex > draggedChallengeIndex
            ? hoveredItem.id
            : hoveredItem.underChallengeId;
        draggedItem.location = CHALLENGE_LOCATIONS.DRAFT_SPRINT;

        updateDraftSprints(newItems);
      },
      500
    ),
    [
      getDraftSprints,
      getBacklog,
      updateDraftSprints,
      updateBacklog,
      cancelDraftSprintsQueries,
      cancelBacklogQueries,
      getSprints,
      cancelSprintsQueries,
    ]
  );

  return {
    moveItemToColumn,
    moveItemInList,
  };
};
