import { Suspense, lazy } from 'react';

import Loader from 'shared_DEPRECATED/components/Loader';
import lazyRetry from 'shared_DEPRECATED/utils/lazyRetry';

import { useExploreChallengesDialog } from 'features/planning';

import { modal, useModalContext } from 'shared/components/Modal';

const ChallengeQuickStartSprintModalContent = lazy(() =>
  lazyRetry(
    () =>
      import(
        'features/planning/components/Board/ExploreChallenges/QuickStartSprint/DialogContentWithBackButton'
      ),
    'QuickStartSprintModalContent'
  )
);

export const useQuickStartSprintModal = () => {
  const { sprint, onSubmit } = useModalContext();
  const onBackBtnClick = useExploreChallengesDialog({
    sprint,
  });

  return ({ qssId }) =>
    () => {
      modal.open({
        content: (
          <Suspense
            fallback={<Loader dataTestid="qss-dialog-loader" size="sm" />}
          >
            <ChallengeQuickStartSprintModalContent
              qssId={qssId}
              onBackBtnClick={onBackBtnClick}
            />
          </Suspense>
        ),
        context: { sprint, onSubmit },
        props: {
          size: 'md',
        },
      });
    };
};
