import { BoardColumnPinButton, Button, ButtonGroup, VARIANTS } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { SPRINT_STATES, SPRINT_STATUS_LABELS } from 'features/sprint/config';
import { SprintStateValues } from 'features/sprint/config/types';

import { BADGE_COLORS, Badge } from 'shared/components/Badge';
import { Typography } from 'shared/components/Typography';

type TPlanningSprintHeader = {
  title: string;
  sprintState: typeof SPRINT_STATES[keyof typeof SPRINT_STATES];
  sprintFormattedDate: string;
  sprintId: string;
  onViewButtonClick: () => void;
};

export const PlanningSprintHeader = ({
  title,
  sprintState,
  sprintFormattedDate,
  sprintId,
  onViewButtonClick,
}: TPlanningSprintHeader) => {
  return (
    <Spacer size="sm md">
      <Flex
        dataTestid="planning-sprint-board-header"
        alignItems="center"
        width="100%"
      >
        <Flex alignItems="flex-start" flexDirection="column">
          <Typography color="gray" fontWeight="medium" type="meta">
            {title}
          </Typography>
          <Flex justifyContent="space-between" width="100%">
            <Flex>
              <Typography color="gray" fontWeight="regular" type="small">
                {sprintFormattedDate}
              </Typography>
              <Spacer size="sm" />
              <Badge bgColor={PLANNING_SPRINT_BADGE_STYLES_MAP[sprintState]}>
                <Typography color="whiteTotal" type="small">
                  {sprintState === SPRINT_STATES.FINISHED
                    ? 'Past'
                    : SPRINT_STATUS_LABELS[sprintState]}
                </Typography>
              </Badge>
            </Flex>
          </Flex>
        </Flex>
        <ButtonGroup spacing="0.5rem" size="sm">
          <BoardColumnPinButton columnId={sprintId} />
          <Button onClick={onViewButtonClick} variant={VARIANTS.TERTIARY}>
            <Typography
              color="black"
              type="meta"
              fontWeight="medium"
              textDecoration="underline"
              sx={{
                textUnderlineOffset: '4px',
              }}
            >
              View
            </Typography>
          </Button>
        </ButtonGroup>
      </Flex>
    </Spacer>
  );
};

const PLANNING_SPRINT_BADGE_STYLES_MAP: Record<
  SprintStateValues,
  BADGE_COLORS
> = {
  [SPRINT_STATES.ACTIVE]: 'green',
  [SPRINT_STATES.FINISHED]: 'blue',
};
