import { useCallback } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';
import { useNavigate } from 'react-router-dom';

import { COACHING_TOOLS_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { ChallengeCard } from 'features/challenge/components/ChallengeCard';
import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
} from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import {
  PlanningSprintHeader,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
  ChallengeType,
  usePlanningAnalyticsEvents,
} from 'features/planning';
import { SPRINT_STATES } from 'features/sprint/config';
import { useSprintLink } from 'features/sprint/hooks';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';

import { PlanningBoardColumnItemDraggable } from './Column/ItemDraggable';

type PlanningFinishedSprintProps = {
  sprintIndex: number;
  sprintId: string;
  sprintTitle: string;
  challenges: ChallengeType[];
  coachingTools: CoachingTool[];
  sprintFormattedDate: string;
  shouldScrollIntoView: boolean;
};

export const PlanningFinishedSprint = ({
  sprintIndex,
  sprintId,
  sprintTitle,
  sprintFormattedDate,
  challenges,
  coachingTools,
  shouldScrollIntoView,
}: PlanningFinishedSprintProps) => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
  const sprintLink = useSprintLink(sprintId);
  const { sendPlanningViewPastSprintClickedEvent } =
    usePlanningAnalyticsEvents();

  const navigate = useNavigate();
  const onViewButtonClick = useCallback(() => {
    sendPlanningViewPastSprintClickedEvent();

    navigate(sprintLink);
  }, [navigate, sendPlanningViewPastSprintClickedEvent, sprintLink]);

  return (
    <PlanningBoardColumn shouldScrollIntoView={shouldScrollIntoView}>
      <Box
        style={{
          height: '100%',
          width: '100%',
          backgroundColor: 'var(--bgCompMinorAccent)',
          borderRadius: 'var(--border-radius12)',
        }}
      >
        <BoardColumnWrapper fraction={1}>
          <BoardColumnHeader minHeight="3.75rem">
            <PlanningSprintHeader
              title={sprintTitle}
              sprintState={SPRINT_STATES.FINISHED}
              sprintFormattedDate={sprintFormattedDate}
              sprintId={sprintId}
              onViewButtonClick={onViewButtonClick}
            />
          </BoardColumnHeader>
          <BoardColumn key={sprintId}>
            <When condition={challenges.length > 0}>
              <PlanningBoardColumnItemsCount
                name="challenge"
                count={challenges.length}
              />
              <For of={challenges}>
                {(challenge, { index: challengeIndex }) => (
                  <PlanningBoardColumnItemDraggable
                    key={`${challenge.challengeId}`}
                    type="challenge"
                    id={challenge.challengeId!}
                    index={challengeIndex}
                    columnIndex={sprintIndex}
                    isColumnChanged={false}
                    fromSprintId={challenge.sprintId}
                    location="finishedSprint"
                  >
                    <Spacer size="zr sm">
                      <ChallengeCard
                        title={challenge.title}
                        picture={challenge.picture}
                        emoji={challenge.emoji}
                        dimensions={challenge.dimensions}
                        target={challenge.target}
                        goals={challenge.goals}
                      />
                    </Spacer>
                  </PlanningBoardColumnItemDraggable>
                )}
              </For>
            </When>
            <When
              condition={isCoachingToolsEnabled && coachingTools.length > 0}
            >
              <PlanningBoardColumnItemsCount
                name="coaching tool"
                count={coachingTools.length}
              />
              <For of={coachingTools}>
                {({ name, description, picture, id }) => (
                  <Spacer size="sm" key={id}>
                    <CoachingToolCard
                      name={name}
                      description={description}
                      url={picture?.url}
                      id={id}
                    >
                      <CoachingToolCardMenu toolId={id}>
                        <CoachingToolCardMenuCopyToSprint toolId={id} />
                      </CoachingToolCardMenu>
                    </CoachingToolCard>
                  </Spacer>
                )}
              </For>
            </When>
          </BoardColumn>
        </BoardColumnWrapper>
      </Box>
    </PlanningBoardColumn>
  );
};
