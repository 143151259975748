import { useRef } from 'react';
import { useDrag } from 'react-dnd';

type DraggableProps<T> = {
  children?: React.ReactNode;
  item: T;
  width?: string;
  canDrop?: (item: T) => boolean;
};

export const Draggable = <T extends { id: string; type: string }>({
  children,
  item,
  width = '100%',
}: DraggableProps<T>) => {
  const ref = useRef(null);

  const [{ isDragging }, drag] = useDrag(() => ({
    type: item.type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor) => monitor.getItem().id === item.id,
  }));

  return (
    <div
      ref={drag}
      style={{
        borderRadius: 'var(--border-radius)',
        backgroundColor: isDragging
          ? 'var(--bgCompSecondaryTiny)'
          : 'transparent',
      }}
    >
      <div
        ref={ref}
        style={{
          cursor: 'move',
          width,
          opacity: isDragging ? 0 : 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};
