import { Box, TabPanel, TabPanelsProps, VStack } from '@chakra-ui/react';

import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  CoachingToolCard,
  CoachingToolCardMenu,
  useCoachingToolsQuery,
  CoachingToolCardMenuCopyToSprint,
} from 'features/coachingTool';
import { PlanningBoardColumnItemsCount } from 'features/planning';

const PlanningBacklogCoachingTools = () => {
  const { data: coachingTools, isLoading } = useCoachingToolsQuery();

  if (isLoading) {
    return <Loader />;
  }

  return coachingTools?.length ? (
    <>
      <Box
        style={{
          position: 'sticky',
          top: '0',
          zIndex: 111,
          backgroundColor: 'var(--bgPrimary)',
        }}
      >
        <Box bg="var(--bgCompSecondaryBig)">
          <PlanningBoardColumnItemsCount
            name="coaching tool"
            count={coachingTools.length}
          />
        </Box>
      </Box>
      <VStack align="stretch" flex="1" spacing={0}>
        {coachingTools.map(({ name, description, picture, id }) => (
          <Spacer size="sm" key={id}>
            <CoachingToolCard
              name={name}
              description={description}
              url={picture?.url}
              id={id}
            >
              <CoachingToolCardMenu toolId={id}>
                <CoachingToolCardMenuCopyToSprint toolId={id} />
              </CoachingToolCardMenu>
            </CoachingToolCard>
          </Spacer>
        ))}
      </VStack>
    </>
  ) : null;
};

type TPlanningBacklogCoachingToolsTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogCoachingToolsTab = (
  props: TPlanningBacklogCoachingToolsTab
) => (
  <TabPanel
    {...props}
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
  >
    <PlanningBacklogCoachingTools />
  </TabPanel>
);
