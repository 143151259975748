import { Box, TabPanel, TabPanelsProps, VStack } from '@chakra-ui/react';

import { ButtonGroup, Flex } from 'shared';
import Loader from 'shared_DEPRECATED/components/Loader';

import {
  PlanningBacklogHeaderMenu,
  usePlanningBacklogFilterContext,
  PlanningBacklogFilterProvider,
  SelectedGoalsProvider,
  PlanningBoardGoalsFilter as PlanningFilter,
  PlanningBoardColumnItemsCount,
  PlanningBoardItems,
  canItemBeDroppedInDropZone,
} from 'features/planning';

const PlanningBacklogChallenges = () => {
  const { filteredChallenges: challenges, isLoading } =
    usePlanningBacklogFilterContext();

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <Box
        style={{
          position: 'sticky',
          top: '0',
          zIndex: 111,
          backgroundColor: 'var(--bgPrimary)',
        }}
      >
        <Flex
          width="100%"
          height="2rem"
          alignItems="center"
          justifyContent="space-between"
          bg="var(--bgCompSecondaryBig)"
        >
          <PlanningBoardColumnItemsCount
            name="challenge"
            count={challenges.length}
          />
          <Flex alignItems="center" height="100%">
            <ButtonGroup spacing="0.5rem" size="sm">
              <PlanningFilter />
              <PlanningBacklogHeaderMenu />
            </ButtonGroup>
          </Flex>
        </Flex>
      </Box>
      <VStack align="stretch" flex="1" spacing={0}>
        <PlanningBoardItems
          challenges={challenges}
          canDrop={canItemBeDroppedInDropZone('backlog')}
        />
      </VStack>
    </>
  );
};

type TPlanningBacklogChallengesTab = TabPanelsProps;
// TabPanelsProps need to be passed in order to make TabPanel component work

export const PlanningBacklogChallengesTab = (
  props: TPlanningBacklogChallengesTab
) => (
  <TabPanel
    m={0}
    p={0}
    height="100%"
    width="100%"
    overflowY="auto"
    overflowX="hidden"
    {...props}
  >
    <SelectedGoalsProvider>
      <PlanningBacklogFilterProvider>
        <PlanningBacklogChallenges />
      </PlanningBacklogFilterProvider>
    </SelectedGoalsProvider>
  </TabPanel>
);
