import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';

import { IPlanningSprint } from 'features/planning';

export const useSprintsQueryActions = () => {
  const queryClient = useQueryClient();

  const cancelSprintsQueries = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.sprints],
    });
  }, [queryClient]);

  const getSprints = useCallback(() => {
    return queryClient.getQueryData<{ items: IPlanningSprint[] }>([
      queryKeys.sprints,
    ]);
  }, [queryClient]);

  const updateSprints = useCallback(
    (newItems: IPlanningSprint[]) => {
      queryClient.setQueryData<{ items: IPlanningSprint[] }>(
        [queryKeys.sprints],
        (old) => {
          return {
            ...old,
            items: newItems,
          };
        }
      );
    },
    [queryClient]
  );

  return {
    cancelSprintsQueries,
    getSprints,
    updateSprints,
  };
};
