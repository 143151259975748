import { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';

type SortableProps<T> = {
  acceptType: string[] | string;
  onHover: (draggedItem: T, hoveredItem: T) => void;
  children?: React.ReactNode;
  item: T;
  onDrop: (item: T) => void;
  width?: string;
};

export const Sortable = <T extends { id: string; type: string }>({
  children,
  onHover,
  item,
  onDrop,
  width = '100%',
}: SortableProps<T>) => {
  const ref = useRef(null);

  const [, drop] = useDrop(() => ({
    accept: item.type,
    drop(itemDropped: T, monitor) {
      if (monitor.isOver()) {
        onDrop(itemDropped);
      }
    },
    hover(itemDragged: T) {
      if (itemDragged.id !== item.id) {
        onHover(itemDragged, item);
      }
    },
  }));

  const [{ isDragging }, drag] = useDrag(() => ({
    type: item.type,
    item,
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
    isDragging: (monitor) => monitor.getItem().id === item.id,
  }));

  drag(ref);
  drop(ref);

  return (
    <div
      style={{
        borderRadius: 'var(--border-radius)',
        backgroundColor: isDragging
          ? 'var(--bgCompSecondaryTiny)'
          : 'transparent',
      }}
    >
      <div
        ref={ref}
        style={{
          cursor: 'move',
          width,
          opacity: isDragging ? 0 : 1,
        }}
      >
        {children}
      </div>
    </div>
  );
};
