import { useMutation, useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';
import { logError } from 'lib/sentry/logError';
import { useMutationHTTPRequest } from 'shared_DEPRECATED/hooks';
import { Nullable } from 'shared_DEPRECATED/types';

import { IChallenge } from 'features/challenge/config/types';
import { IPlanningDraftSprint } from 'features/planning';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

type MovePlanningChallengeOptionsType = {
  id: string;
  fromBacklog: boolean;
  fromSprintId?: string;
  toBacklog?: boolean;
  toSprintId?: string;
  underChallengeId: Nullable<string>;
};

export const useMovePlanningChallengeMutation__NEW = () => {
  const { userId } = useCurrentModeUser();
  const queryClient = useQueryClient();
  const { request } = useMutationHTTPRequest();

  return useMutation(
    // @ts-ignore
    async ({
      id,
      fromBacklog,
      fromSprintId,
      toBacklog,
      toSprintId,
      underChallengeId,
    }: MovePlanningChallengeOptionsType) =>
      await request({
        url: `/api/web/sprint-planning/challenges/${id}/move`,
        body: {
          fromBacklog,
          fromSprintId,
          toBacklog,
          toSprintId,
          underChallengeId,
        },
      }),
    {
      onMutate: async () => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.challenges],
        });

        queryClient.cancelQueries({
          queryKey: [queryKeys.draftSprints],
        });

        const previousChallengeBacklogValue = queryClient.getQueryData([
          queryKeys.challenges,
          userId,
        ]);

        const previousDraftSprintsValue = queryClient.getQueryData([
          queryKeys.draftSprints,
          userId,
        ]);

        return { previousChallengeBacklogValue, previousDraftSprintsValue };
      },
      onError: (
        err: Error,
        _: any,
        context: {
          previousChallengeBacklogValue?: IChallenge[];
          previousDraftSprintsValue: IPlanningDraftSprint[];
        }
      ) => {
        queryClient.setQueryData(
          [queryKeys.challenges],
          context?.previousChallengeBacklogValue
        );

        queryClient.setQueryData(
          [queryKeys.draftSprints],
          context?.previousDraftSprintsValue
        );

        logError(err);
      },
      onSuccess: (_, { fromBacklog, fromSprintId, toBacklog, toSprintId }) => {
        (fromBacklog || toBacklog) &&
          queryClient.invalidateQueries([queryKeys.challenges]);
        (fromSprintId || toSprintId) &&
          queryClient.invalidateQueries([queryKeys.draftSprints]);
      },
    }
  );
};
