import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';

import { ColumnsType } from 'features/goal';
import { useCurrentModeUser } from 'features/user/hooks/useCurrentModeUser';

export const useMoveRoadmapGoals = () => {
  const queryClient = useQueryClient();
  const { userId } = useCurrentModeUser();

  return {
    moveItemInList: useCallback(
      async (
        draggedItem: {
          id: string;
          columnIndex: number;
          index: number;
          isColumnChanged: boolean;
        },
        hoveredItem: {
          id: string;
          columnIndex: number;
        }
      ) => {
        await queryClient.cancelQueries({
          queryKey: [queryKeys.goals, userId],
        });

        const roadmap = queryClient.getQueryData<{ columns: ColumnsType }>([
          queryKeys.goals,
          userId,
        ]);

        if (roadmap) {
          const { columns } = roadmap;
          const { columnIndex: draggedColumnIndex } = draggedItem;
          const { columnIndex: hoveredColumnIndex } = hoveredItem;
          const draggedGoalIndex = columns[draggedColumnIndex].goals.findIndex(
            (goal) => goal.goalId === draggedItem.id
          );
          const hoveredGoalIndex = columns[hoveredColumnIndex].goals.findIndex(
            (goal) => goal.goalId === hoveredItem.id
          );
          const draggedGoal =
            columns[draggedColumnIndex].goals[draggedGoalIndex];

          draggedItem.index = hoveredGoalIndex;
          draggedItem.columnIndex = hoveredColumnIndex;
          draggedItem.isColumnChanged =
            hoveredColumnIndex !== draggedColumnIndex;
          const newColumns = [...columns];
          newColumns[draggedColumnIndex].goals.splice(draggedGoalIndex, 1);
          newColumns[hoveredColumnIndex].goals.splice(
            hoveredGoalIndex,
            0,
            draggedGoal
          );

          queryClient.setQueryData<{ columns: ColumnsType }>(
            [queryKeys.goals, userId],
            (old) => ({
              ...old,
              columns: newColumns,
            })
          );
        }
      },
      [queryClient, userId]
    ),
    moveItemToColumn: useCallback(
      (modifiedColumnIndex: number) =>
        async (item: {
          id: string;
          columnIndex: number;
          index: number;
          isColumnChanged: boolean;
        }) => {
          if (item.columnIndex === modifiedColumnIndex) {
            return;
          }

          await queryClient.cancelQueries({
            queryKey: [queryKeys.goals, userId],
          });

          const roadmap = queryClient.getQueryData<{ columns: ColumnsType }>([
            queryKeys.goals,
            userId,
          ]);

          if (roadmap) {
            const { columns } = roadmap;
            const { columnIndex } = item;
            const goalIndex = columns[columnIndex].goals.findIndex(
              (goal) => goal.goalId === item.id
            );
            const goal = columns[columnIndex].goals[goalIndex];
            const newColumns = [...columns];
            newColumns[columnIndex].goals.splice(goalIndex, 1);

            item.columnIndex = modifiedColumnIndex;
            item.index = newColumns[modifiedColumnIndex].goals.length;
            item.isColumnChanged = true;

            newColumns[modifiedColumnIndex].goals.push(goal);

            queryClient.setQueryData<{ columns: ColumnsType }>(
              [queryKeys.goals, userId],
              (old) => ({
                ...old,
                columns: newColumns,
              })
            );
          }
        },
      [queryClient, userId]
    ),
  };
};
