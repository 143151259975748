import { GOAL_BREAKDOWN_DND_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import Box from 'shared_DEPRECATED/components/Box';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import Loader from 'shared_DEPRECATED/components/Loader';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  GoalBreakdownTitle,
  GoalBreakdownContent,
  useGoalBreakdownQuery,
  GoalFocusAreaHeaderMenu,
} from 'features/goal';

import { BoardColumnWrapper } from 'shared/components/Board';

import { GoalBreakdownContent__NEW } from './Board/Content__NEW';

export const GoalBreakdownBoard = () => {
  const { isLoading, data: goalBreakdown } = useGoalBreakdownQuery();
  const isImprovedGoalBreakdownDndEnabled = useFeatureFlag(
    GOAL_BREAKDOWN_DND_FEATURE_FLAG
  );

  return (
    <Flex flexDirection="column" width="100%" height="100%">
      <Flex width="100%">
        <GoalBreakdownTitle />
        <GoalFocusAreaHeaderMenu />
      </Flex>
      <Spacer size="md" />
      <Box style={{ width: '100%', height: '100%', overflowY: 'auto' }}>
        {isLoading ? (
          <Loader size="sm" />
        ) : (
          <BoardColumnWrapper fraction={1}>
            {isImprovedGoalBreakdownDndEnabled ? (
              // eslint-disable-next-line
              <GoalBreakdownContent__NEW
                challenges={goalBreakdown.challenges}
                focusAreas={goalBreakdown.focusAreas}
              />
            ) : (
              <GoalBreakdownContent
                challenges={goalBreakdown.challenges}
                focusAreas={goalBreakdown.focusAreas}
              />
            )}
          </BoardColumnWrapper>
        )}
      </Box>
    </Flex>
  );
};
