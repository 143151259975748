import { useParams, useMatch, useNavigate } from 'react-router-dom';

import { USER_TYPES } from 'shared_DEPRECATED/types/User';

import { ACCOUNTABILITY_PARTNER_SPRINT_ROUTE_PATH } from 'features/router/config';
import { SPRINT_STATES } from 'features/sprint/config';
import useSprintQuery from 'features/sprint/hooks/query/useSprint';
import { useUserContext } from 'features/user/context';
import { useUserMode } from 'features/user/hooks';

const DRAFT_SPRINT_UNAVAILABLE_ERROR = 'draft_unavailable_during_active_sprint';

export const useCurrentSprint = () => {
  const userMode = useUserMode();
  const user = useUserContext();
  const { sprintId, email } = useParams();
  const navigate = useNavigate();
  const accountabilityPartnerSprintRouteMatch = useMatch(
    ACCOUNTABILITY_PARTNER_SPRINT_ROUTE_PATH
  );
  const userId =
    userMode === USER_TYPES.USER && !accountabilityPartnerSprintRouteMatch
      ? user.email
      : email;

  const navigateToParticipantPage = () => {
    navigate(`/coach/participants/${email}`, { replace: true });
  };

  return useSprintQuery({
    userId: sprintId === SPRINT_STATES.DRAFT ? userId : '',
    sprintId,
    options: {
      enabled: Boolean(sprintId),
      onError: (error) => {
        if (error.name === DRAFT_SPRINT_UNAVAILABLE_ERROR) {
          navigateToParticipantPage();
        }
      },
    },
  });
};
