import { memo, useCallback } from 'react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
  CoachingToolCardMenuMoveToSprint,
  CoachingToolCardMenuRemoveButton,
} from 'features/coachingTool';
import {
  PlanningDraftSprintHeader,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
  doesDraggedChallengeExistInColumn,
} from 'features/planning';
import { PlanningBoardItems__NEW } from 'features/planning/components/Board/Items__NEW';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';
import { useMoveSprintChallenge } from 'features/planning/hooks/useMoveSprintChallenge';
import {
  IPlanningDraftSprint,
  PlanningDraggableItem,
} from 'features/planning/types';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';
import { Droppable } from 'shared/components/DragNDrop/Droppable';

export const PlanningDraftSprints__NEW = memo(
  ({ sprints }: { sprints: IPlanningDraftSprint[] }) => {
    const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
    const { mutate } = useMovePlanningChallengeMutation__NEW();
    const { moveItemToColumn, moveItemInList } = useMoveSprintChallenge();

    const onDrop = useCallback(
      (sprint) => (item: PlanningDraggableItem) => {
        if (
          doesDraggedChallengeExistInColumn({
            challenges: sprint.challenges,
            draggedItemId: item.id,
            draggedItemLocation: item.location,
            currentLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
          })
        ) {
          return;
        }

        !item.isColumnChanged &&
          mutate({
            id: item.id,
            fromBacklog: item.fromBacklog,
            fromSprintId: item.fromSprintId,
            toBacklog: false,
            toSprintId: sprint?.sprintId,
            underChallengeId: item.underChallengeId,
          });
      },
      [mutate]
    );

    const onHover = useCallback(
      (sprint) =>
        (
          draggedItem: PlanningDraggableItem,
          hoveredItem: PlanningDraggableItem
        ) => {
          if (
            doesDraggedChallengeExistInColumn({
              challenges: sprint.challenges,
              draggedItemId: draggedItem.id,
              draggedItemLocation: draggedItem.location,
              currentLocation: CHALLENGE_LOCATIONS.DRAFT_SPRINT,
            })
          ) {
            return;
          }

          moveItemInList(draggedItem, hoveredItem);
        },
      [moveItemInList]
    );

    return (
      <>
        {sprints.map((sprint) => {
          return (
            <Droppable
              key={sprint.sprintId}
              acceptType={['challenge']}
              onDrop={({
                isColumnChanged,
                id,
                fromBacklog,
                fromSprintId,
                underChallengeId,
              }: PlanningDraggableItem) => {
                if (!isColumnChanged) {
                  return;
                }

                mutate({
                  id,
                  fromBacklog,
                  fromSprintId,
                  toBacklog: false,
                  toSprintId: sprint.sprintId,
                  underChallengeId,
                });
              }}
              onHover={
                sprint.challenges.length === 0
                  ? moveItemToColumn(
                      sprint.index,
                      CHALLENGE_LOCATIONS.DRAFT_SPRINT
                    )
                  : undefined
              }
            >
              <PlanningBoardColumn key={sprint.sprintId}>
                <BoardColumnWrapper
                  fraction={sprints.length}
                  key={sprint.sprintId}
                >
                  <BoardColumnHeader key={sprint.sprintId} minHeight="3.75rem">
                    <PlanningDraftSprintHeader sprint={sprint} />
                  </BoardColumnHeader>
                  <BoardColumn>
                    {sprint.challenges.length > 0 && (
                      <PlanningBoardColumnItemsCount
                        name="challenge"
                        count={sprint.challenges.length}
                      />
                    )}
                    <>
                      {sprint.challenges.length > 0 ? (
                        // eslint-disable-next-line
                        <PlanningBoardItems__NEW
                          challenges={sprint.challenges}
                          sprint={sprint}
                          sprintIndex={sprint.index}
                          onDrop={onDrop(sprint)}
                          onHover={onHover(sprint)}
                        />
                      ) : null}
                    </>
                    {isCoachingToolsEnabled && sprint.coachingTools.length > 0 && (
                      <>
                        <PlanningBoardColumnItemsCount
                          name="coaching tool"
                          count={sprint.coachingTools.length}
                        />
                        {sprint.coachingTools.map(
                          ({ name, description, picture, id }) => (
                            <Spacer size="sm" key={id}>
                              <CoachingToolCard
                                name={name}
                                description={description}
                                url={picture?.url}
                                id={id}
                              >
                                <CoachingToolCardMenu toolId={id}>
                                  <CoachingToolCardMenuCopyToSprint
                                    toolId={id}
                                  />
                                  <CoachingToolCardMenuMoveToSprint
                                    toolId={id}
                                    sprintId={sprint.sprintId}
                                  />
                                  <CoachingToolCardMenuRemoveButton
                                    toolId={id}
                                    sprintId={sprint.sprintId}
                                  />
                                </CoachingToolCardMenu>
                              </CoachingToolCard>
                            </Spacer>
                          )
                        )}
                      </>
                    )}
                  </BoardColumn>
                </BoardColumnWrapper>
              </PlanningBoardColumn>
            </Droppable>
          );
        })}
      </>
    );
  }
);
