import { memo, ReactNode, useMemo } from 'react';

import Spacer from 'shared_DEPRECATED/components/Spacer';

import { TChallengeLocation } from 'features/challenge/config/types';

import { Draggable } from 'shared/components/DragNDrop/Draggable';

type PlanningBoardColumnItemDraggableProps = {
  type: string;
  id: string;
  columnIndex: number;
  index: number;
  children: ReactNode;
  isColumnChanged: boolean;
  fromSprintId?: string;
  location: TChallengeLocation;
};

export const PlanningBoardColumnItemDraggable = memo(
  ({
    type,
    id,
    columnIndex,
    index,
    children,
    fromSprintId,
    location,
  }: PlanningBoardColumnItemDraggableProps) => {
    const item = useMemo(
      () => ({
        type,
        id,
        index,
        columnIndex,
        underChallengeId: null,
        isColumnChanged: false,
        fromBacklog: false,
        toSprintId: undefined,
        fromSprintId,
        toBacklog: false,
        location,
      }),
      [fromSprintId, columnIndex, id, index, type, location]
    );

    return (
      <>
        <Draggable item={item}>{children}</Draggable>
        <Spacer size="xsm" />
      </>
    );
  }
);
