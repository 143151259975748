import { TabPanels, Tabs } from '@chakra-ui/react';
import { memo } from 'react';

import { COACHING_TOOLS_FEATURE_FLAG, SPRINT_PLANNING_DND } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';

import {
  PlanningBacklogHeader,
  PlanningBoardColumn,
  PlanningBacklogCoachingToolsTab,
  PlanningBacklogChallengesTab,
} from 'features/planning';

import { BoardColumnHeader, BoardColumnWrapper } from 'shared/components/Board';

import { PlanningBacklogChallengesTab__NEW } from './Backlog/ChallengesTab__NEW';

export const PlanningBacklog = memo(() => {
  const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
  const isSprintPlanningDndEnabled = useFeatureFlag(SPRINT_PLANNING_DND);

  return (
    <PlanningBoardColumn>
      <BoardColumnWrapper fraction={2}>
        <Tabs
          variant="unstyled"
          height="100%"
          isLazy
          lazyBehavior="keepMounted"
        >
          <BoardColumnHeader minHeight={columnHeaderMinHeight}>
            <PlanningBacklogHeader />
          </BoardColumnHeader>
          <TabPanels height={`calc(100% - ${columnHeaderMinHeight})`}>
            {isSprintPlanningDndEnabled ? (
              // eslint-disable-next-line
              <PlanningBacklogChallengesTab__NEW />
            ) : (
              <PlanningBacklogChallengesTab />
            )}
            {isCoachingToolsEnabled && <PlanningBacklogCoachingToolsTab />}
          </TabPanels>
        </Tabs>
      </BoardColumnWrapper>
    </PlanningBoardColumn>
  );
});

const columnHeaderMinHeight = '3.75rem';
