import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';

import { ChallengeType } from './useBacklog';

export const usePlanningBacklogQueryActions = () => {
  const queryClient = useQueryClient();

  const cancelBacklogQueries = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.challenges],
    });
  }, [queryClient]);

  const getBacklog = useCallback(() => {
    return queryClient.getQueryData<{ items: ChallengeType[] }>([
      queryKeys.challenges,
    ]);
  }, [queryClient]);

  const updateBacklog = useCallback(
    (newItems: ChallengeType[]) => {
      queryClient.setQueryData<{ items: ChallengeType[] }>(
        [queryKeys.challenges],
        (old) => ({
          ...old,
          items: newItems,
        })
      );
    },
    [queryClient]
  );

  return {
    cancelBacklogQueries,
    getBacklog,
    updateBacklog,
  };
};
