import { useLocation, useParams } from 'react-router-dom';

import { getPathParams } from 'shared_DEPRECATED/utils';
import { routeNames } from 'shared_DEPRECATED/utils/config';

import { sharedSprintLinks } from 'features/sprint/utils';

const SPRINT_URL_RESOLVER_MAP = {
  [routeNames.COACH]: (sprintId, email) =>
    `${sharedSprintLinks.participants}/${email}/${sprintId}`,
  [routeNames.CONNECTIONS]: (sprintId, email) =>
    `/users/${email}/sprints/${sprintId}`,
};

export const useSprintLink = (sprintId) => {
  const { email } = useParams();
  const { pathname } = useLocation();
  let [firstPathParam] = getPathParams(pathname);

  const sprintUrlResolver = SPRINT_URL_RESOLVER_MAP[firstPathParam];

  return (
    sprintUrlResolver?.(sprintId, email) ||
    `/${routeNames.DASHBOARD}/${sprintId}`
  );
};
