import { memo } from 'react';
import { Else, If, Then } from 'react-if';
import { For } from 'react-loops';

import { PlanningSprintModel } from 'features/planning';
import { SPRINT_STATES } from 'features/sprint/config';

import { PlanningActiveSprint } from './ActiveSprint';
import { PlanningFinishedSprint } from './FinishedSprint';

export const PlanningActiveFinishedSprints = memo(
  ({ sprints }: { sprints: PlanningSprintModel[] }) => {
    return (
      <>
        <For of={sprints}>
          {(sprint: PlanningSprintModel, { isLast, index }) => {
            const reversedIndex = Math.abs(index - sprints.length + 1);

            return (
              <If condition={sprint.state === SPRINT_STATES.ACTIVE}>
                <Then>
                  <PlanningActiveSprint
                    sprintId={sprint.sprintId}
                    sprintTitle={sprint.title}
                    challenges={sprint.challenges}
                    coachingTools={sprint.coachingTools}
                    sprintFormattedDate={sprint.formattedDate}
                  />
                </Then>
                <Else>
                  <PlanningFinishedSprint
                    sprintId={sprint.sprintId}
                    sprintTitle={sprint.title}
                    challenges={sprint.challenges}
                    coachingTools={sprint.coachingTools}
                    sprintFormattedDate={sprint.formattedDate}
                    shouldScrollIntoView={isLast}
                    sprintIndex={reversedIndex}
                  />
                </Else>
              </If>
            );
          }}
        </For>
      </>
    );
  }
);
