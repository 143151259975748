import { Box, Typography, Button } from 'shared';
import { Flex } from 'shared_DEPRECATED/components/Flex';
import { iconNames, Icon } from 'shared_DEPRECATED/components/Icon';

import {
  IPlanningDraftSprint,
  useAddPlanningCoachingToolMutation,
  usePlanningDraftSprintsQuery,
  filterSprintsByCoachingTool,
} from 'features/planning';

import { Dropdown } from 'shared/components/Dropdown';

type TCoachingToolCardMenuCopyToSprint = {
  toolId: string;
};

export const CoachingToolCardMenuCopyToSprint = ({
  toolId,
}: TCoachingToolCardMenuCopyToSprint) => {
  const { data: draftSprints = [] } = usePlanningDraftSprintsQuery<
    IPlanningDraftSprint[]
  >({
    options: {
      select: (sprints) => filterSprintsByCoachingTool(sprints, toolId),
    },
  });

  const { mutateAsync: copyCoachingTool } =
    useAddPlanningCoachingToolMutation();

  const handleCoachingToolCopy = async (sprintId: string) =>
    await copyCoachingTool({
      toolId,
      sprintId,
    });

  return (
    <Dropdown
      anchorContent={
        <Flex width="100%" justifyContent="space-between">
          <Typography color="black">Copy to</Typography>
          <Icon name={iconNames.arrowRight} />
        </Flex>
      }
      anchorSpacing={['zr md', 'md']}
      placement="right-start"
      withChevronIcon={false}
    >
      <Box style={{ width: '100%', overflow: 'auto' }} role="listbox">
        <Flex alignItems="stretch" flexDirection="column">
          {draftSprints.map(({ sprintId, title }) => (
            <Button
              key={sprintId}
              _focus={{ outline: 'none' }}
              onClick={() => handleCoachingToolCopy(sprintId)}
              width="100%"
            >
              <Typography color="black">{title}</Typography>
            </Button>
          ))}
        </Flex>
      </Box>
    </Dropdown>
  );
};
