import { Box } from '@chakra-ui/react';
import { ReactNode } from 'react';

import { Flex } from 'shared_DEPRECATED/components/Flex';
import Spacer from 'shared_DEPRECATED/components/Spacer';
import { Nullable } from 'shared_DEPRECATED/types';
import { Picture } from 'shared_DEPRECATED/types/Picture';
import { capitalize, getPictureUrl } from 'shared_DEPRECATED/utils';

import { ChallengeInfo } from 'features/challenge/components/Info';
import {
  ChallengeGoalsType,
  ChallengeTargetProgressType,
  WeekdayTargetProgressType,
} from 'features/challenge/config/types';
import { getChallengeTargetText } from 'features/challenge/utils';
import {
  getChallengeFrequencyText,
  shouldShowFrequency,
} from 'features/sprint/utils';

import { Typography } from 'shared/components/Typography';

type TPlanningChallengeCard = {
  title: string;
  picture: Nullable<Picture>;
  emoji: Nullable<string>;
  dimensions: string[];
  target: ChallengeTargetProgressType;
  children?: ReactNode;
  goals: ChallengeGoalsType;
};

export const ChallengeCard = ({
  title,
  picture,
  emoji,
  target,
  children,
  goals,
}: TPlanningChallengeCard) => {
  // if target has amount and period, get challenge target text
  const amount = (target as WeekdayTargetProgressType)?.amount;
  const period = (target as WeekdayTargetProgressType)?.period;
  const hasTarget = amount && period;
  const targetText = hasTarget ? getChallengeTargetText(amount, period) : null;
  const showFrequency = shouldShowFrequency(target);
  const frequencyText = showFrequency
    ? getChallengeFrequencyText(target)
    : null;

  return (
    <Box
      style={{
        width: '100%',
        borderRadius: 'var(--border-radius)',
        position: 'relative',
        cursor: 'pointer',
      }}
      _hover={{
        bg: 'var(--bgCompSecondaryMid)',
      }}
      bg="var(--bgCompPrimary)"
      data-group
      data-testid="challenge-card"
    >
      <Spacer size="sm">
        <Flex width="100%">
          <Flex>
            <ChallengeInfo
              emoji={emoji}
              pictureUrl={getPictureUrl(picture)}
              title={title}
              isCropped={false}
            >
              <Typography color="gray" type="small">
                {targetText}
              </Typography>
              <Typography color="gray" type="small">
                {frequencyText}
              </Typography>
              <Spacer size="sm zr zr">
                {goals.length ? (
                  <Box style={{ maxWidth: '100%' }}>
                    <Typography
                      whiteSpace="pre-wrap"
                      color="gray"
                      type="small"
                      fontWeight="regular"
                    >
                      {goals.map((goal) => capitalize(goal.title)).join(' • ')}
                    </Typography>
                  </Box>
                ) : null}
              </Spacer>
            </ChallengeInfo>
          </Flex>
          <Box
            visibility="hidden"
            _groupHover={{
              visibility: 'visible',
            }}
            onClick={(evt) => evt.stopPropagation()}
          >
            {children}
          </Box>
        </Flex>
      </Spacer>
    </Box>
  );
};
