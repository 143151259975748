import { NonRepeatedChallenge } from 'features/challenge/NonRepeatedChallenge';
import { RepeatedChallenge } from 'features/challenge/RepeatedChallenge';
import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  IRepeatedChallenge,
  INonRepeatedChallenge,
} from 'features/challenge/config/types';
import { ICoachingTool } from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import { FinalSprint } from 'features/sprint';
import { SPRINT_STATES, SPRINT_TYPES } from 'features/sprint/config';
import { IFinalSprint, ISprint } from 'features/sprint/config/types';

export interface IPlanningSprint extends Omit<ISprint, 'oneTimeChallenges'> {}

class PlanningSprint extends FinalSprint implements IFinalSprint {
  challenges: (IRepeatedChallenge | INonRepeatedChallenge)[];
  readonly type: typeof SPRINT_TYPES[keyof typeof SPRINT_TYPES];
  coachingTools: ICoachingTool[];
  index: number;

  constructor(sprint: IPlanningSprint) {
    super(sprint);

    this.index = -1;
    this.challenges = sprint.challenges.map((challenge) =>
      this.isNonRepeatedChallenge(challenge)
        ? new NonRepeatedChallenge({
            ...challenge,
            sprintId: sprint.sprintId,
            location:
              sprint.state === SPRINT_STATES.ACTIVE
                ? CHALLENGE_LOCATIONS.ACTIVE_SPRINT
                : CHALLENGE_LOCATIONS.FINISHED_SPRINT,
          })
        : new RepeatedChallenge({
            ...challenge,
            sprintId: sprint.sprintId,
            location:
              sprint.state === SPRINT_STATES.ACTIVE
                ? CHALLENGE_LOCATIONS.ACTIVE_SPRINT
                : CHALLENGE_LOCATIONS.FINISHED_SPRINT,
          })
    );
    this.coachingTools = sprint.coachingTools.map(
      (coachingTool) => new CoachingTool(coachingTool)
    );
    this.type = sprint.type;
  }

  isNonRepeatedChallenge(
    challenge: INonRepeatedChallenge | IRepeatedChallenge
  ): challenge is INonRepeatedChallenge {
    return !!(challenge as INonRepeatedChallenge).target?.dueDate;
  }
}

export { PlanningSprint as PlanningSprintModel };
