import { useCallback } from 'react';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';

import { queryKeys } from 'api/config';

import { GoalBreakdownType } from 'features/goal';

export const useMoveGoalBreakdownChallenges = () => {
  const queryClient = useQueryClient();
  const { goalId } = useParams();

  return {
    moveToFocusArea: useCallback(
      (modifiedFocusAreaIndex) =>
        async (item: { id: string; focusAreaIndex: number; index: number }) => {
          if (item.focusAreaIndex === modifiedFocusAreaIndex) {
            return;
          }

          const goalBreakdown = queryClient.getQueryData<GoalBreakdownType>([
            queryKeys.goalBreakdown,
            goalId,
          ]);

          if (!goalBreakdown) {
            return;
          }

          const { focusAreas, challenges } = goalBreakdown;

          await queryClient.cancelQueries({
            queryKey: [queryKeys.goalBreakdown, goalId],
          });

          const { focusAreaIndex } = item;
          let challenge;
          const newChallenges = [...challenges];
          const newFocusAreas = [...focusAreas];

          const challengeIndex =
            focusAreaIndex > -1
              ? focusAreas[focusAreaIndex].challenges.findIndex(
                  (challenge) => challenge.customChallengeId === item.id
                )
              : challenges.findIndex(
                  (challenge) => challenge.customChallengeId === item.id
                );

          challenge =
            focusAreaIndex > -1
              ? focusAreas[focusAreaIndex].challenges[challengeIndex]
              : challenges[challengeIndex];

          focusAreaIndex > -1
            ? newFocusAreas[focusAreaIndex].challenges.splice(challengeIndex, 1)
            : newChallenges.splice(challengeIndex, 1);
          item.index =
            modifiedFocusAreaIndex > -1
              ? newFocusAreas[modifiedFocusAreaIndex].challenges.length
              : newChallenges.length;

          item.focusAreaIndex = modifiedFocusAreaIndex;

          modifiedFocusAreaIndex > -1
            ? newFocusAreas[modifiedFocusAreaIndex].challenges.push(challenge)
            : newChallenges.push(challenge);

          queryClient.setQueryData<GoalBreakdownType>(
            [queryKeys.goalBreakdown, goalId],
            (old) => ({
              ...old!,
              challenges: newChallenges,
              focusAreas: newFocusAreas,
            })
          );
        },
      [goalId, queryClient]
    ),
    moveToChallenges: useCallback(
      (modifiedFocusAreaIndex) =>
        async (
          draggedItem: {
            id: string;
            focusAreaIndex: number;
            index: number;
          },
          hoveredItem: {
            id: string;
            focusAreaIndex: number;
          }
        ) => {
          await queryClient.cancelQueries({
            queryKey: [queryKeys.goalBreakdown, goalId],
          });

          const goalBreakdown = queryClient.getQueryData<GoalBreakdownType>([
            queryKeys.goalBreakdown,
            goalId,
          ]);

          if (!goalBreakdown) {
            return;
          }

          const { focusAreas, challenges } = goalBreakdown;

          let newFocusAreas = [...focusAreas];
          let newChallenges = [...challenges];

          const draggedChallengeIndex = (
            draggedItem.focusAreaIndex > -1
              ? focusAreas[draggedItem.focusAreaIndex].challenges
              : challenges
          ).findIndex(
            (challenge) => challenge.customChallengeId === draggedItem.id
          );
          const draggedChallenge = (
            draggedItem.focusAreaIndex > -1
              ? focusAreas[draggedItem.focusAreaIndex].challenges
              : challenges
          )[draggedChallengeIndex];
          const hoveredChallengeIndex =
            modifiedFocusAreaIndex === -1
              ? challenges.findIndex((challenge) => {
                  return challenge.customChallengeId === hoveredItem.id;
                })
              : focusAreas[modifiedFocusAreaIndex].challenges.findIndex(
                  (challenge) => {
                    return challenge.customChallengeId === hoveredItem.id;
                  }
                );

          draggedItem.focusAreaIndex > -1
            ? newFocusAreas[draggedItem.focusAreaIndex].challenges.splice(
                draggedChallengeIndex,
                1
              )
            : newChallenges.splice(draggedChallengeIndex, 1);

          if (modifiedFocusAreaIndex === -1) {
            newChallenges.splice(hoveredChallengeIndex, 0, draggedChallenge);
          } else {
            newFocusAreas[modifiedFocusAreaIndex].challenges.splice(
              hoveredChallengeIndex,
              0,
              draggedChallenge
            );
          }

          draggedItem.index = hoveredChallengeIndex;
          draggedItem.focusAreaIndex = hoveredItem.focusAreaIndex;

          queryClient.setQueryData<GoalBreakdownType>(
            [queryKeys.goalBreakdown, goalId],
            (old) => ({
              ...old!,
              focusAreas: newFocusAreas,
              challenges: newChallenges,
            })
          );
        },
      [goalId, queryClient]
    ),
  };
};
