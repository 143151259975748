import { memo, useCallback } from 'react';
import { When } from 'react-if';
import { For } from 'react-loops';
import { useNavigate } from 'react-router-dom';

import { COACHING_TOOLS_FEATURE_FLAG, useFeatureFlag } from 'featureFlags';
import Box from 'shared_DEPRECATED/components/Box';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import { ChallengeCard } from 'features/challenge/components/ChallengeCard';
import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
} from 'features/coachingTool';
import { CoachingTool } from 'features/coachingTool/CoachingTool';
import {
  PlanningSprintHeader,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
  usePlanningAnalyticsEvents,
  ChallengeType,
  PlanningDraggableItem,
  doesDraggedChallengeExistInColumn,
} from 'features/planning';
import { useMovePlanningChallengeMutation__NEW } from 'features/planning/hooks/mutation/useMovePlanningChallenge__NEW';
import { useMoveActiveFinishedSprintChallenge } from 'features/planning/hooks/useMoveActiveFinishedSprintChallenge';
import { SPRINT_STATES } from 'features/sprint/config';
import { useSprintLink } from 'features/sprint/hooks';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';

import { PlanningBoardColumnItem } from './Column/Item';

type PlanningActiveSprintProps = {
  sprintId: string;
  sprintTitle: string;
  challenges: ChallengeType[];
  coachingTools: CoachingTool[];
  sprintFormattedDate: string;
};

export const PlanningActiveSprint = memo(
  ({
    sprintId,
    sprintTitle,
    sprintFormattedDate,
    challenges,
    coachingTools,
  }: PlanningActiveSprintProps) => {
    const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);
    const sprintLink = useSprintLink(sprintId);
    const { sendPlanningViewCurrentSprintClickedEvent } =
      usePlanningAnalyticsEvents();
    const { mutate } = useMovePlanningChallengeMutation__NEW();
    const { moveItemInList } = useMoveActiveFinishedSprintChallenge();
    const navigate = useNavigate();
    const onViewButtonClick = useCallback(() => {
      sendPlanningViewCurrentSprintClickedEvent();

      navigate(sprintLink);
    }, [navigate, sendPlanningViewCurrentSprintClickedEvent, sprintLink]);

    return (
      <PlanningBoardColumn shouldScrollIntoView={true}>
        <Box
          style={{
            height: '100%',
            width: '100%',
            backgroundColor: 'var(--bgCompMinorSuccess)',
            borderRadius: 'var(--border-radius12)',
          }}
        >
          <BoardColumnWrapper fraction={1}>
            <BoardColumnHeader minHeight="3.75rem">
              <PlanningSprintHeader
                title={sprintTitle}
                sprintState={SPRINT_STATES.ACTIVE}
                sprintFormattedDate={sprintFormattedDate}
                sprintId={sprintId}
                onViewButtonClick={onViewButtonClick}
              />
            </BoardColumnHeader>
            <BoardColumn>
              <When condition={challenges.length > 0}>
                <PlanningBoardColumnItemsCount
                  name="challenge"
                  count={challenges.length}
                />
                <For of={challenges}>
                  {(challenge, { index: challengeIndex }) => {
                    const underChallengeId =
                      challenges[challengeIndex - 1]?.challengeId || null;

                    return (
                      <PlanningBoardColumnItem
                        key={`${challenge.challengeId}`}
                        type="challenge"
                        id={challenge.challengeId!}
                        index={challengeIndex}
                        columnIndex={0}
                        isColumnChanged={false}
                        onDrop={(item: PlanningDraggableItem) => {
                          if (
                            doesDraggedChallengeExistInColumn({
                              challenges,
                              draggedItemId: item.id,
                              draggedItemLocation: item.location,
                              currentLocation:
                                CHALLENGE_LOCATIONS.ACTIVE_SPRINT,
                            })
                          ) {
                            return;
                          }

                          mutate({
                            id: item.id,
                            fromBacklog: item.fromBacklog,
                            fromSprintId: item.fromSprintId,
                            toBacklog: false,
                            toSprintId: sprintId,
                            underChallengeId: item.underChallengeId,
                          });
                        }}
                        onHover={(
                          draggedItem: PlanningDraggableItem,
                          hoveredItem: PlanningDraggableItem
                        ) => {
                          if (
                            doesDraggedChallengeExistInColumn({
                              challenges,
                              draggedItemId: draggedItem.id,
                              draggedItemLocation: draggedItem.location,
                              currentLocation:
                                CHALLENGE_LOCATIONS.ACTIVE_SPRINT,
                            })
                          ) {
                            return;
                          }

                          moveItemInList(draggedItem, hoveredItem);
                        }}
                        fromSprintId={challenge.sprintId}
                        underChallengeId={underChallengeId}
                        location="activeSprint"
                      >
                        <Spacer size="zr sm">
                          <ChallengeCard
                            title={challenge.title}
                            picture={challenge.picture}
                            emoji={challenge.emoji}
                            dimensions={challenge.dimensions}
                            target={challenge.target}
                            goals={challenge.goals}
                          />
                        </Spacer>
                      </PlanningBoardColumnItem>
                    );
                  }}
                </For>
              </When>
              <When
                condition={isCoachingToolsEnabled && coachingTools.length > 0}
              >
                <PlanningBoardColumnItemsCount
                  name="coaching tool"
                  count={coachingTools.length}
                />
                <For of={coachingTools}>
                  {({ name, description, picture, id }) => (
                    <Spacer size="sm" key={id}>
                      <CoachingToolCard
                        name={name}
                        description={description}
                        url={picture?.url}
                        id={id}
                      >
                        <CoachingToolCardMenu toolId={id}>
                          <CoachingToolCardMenuCopyToSprint toolId={id} />
                        </CoachingToolCardMenu>
                      </CoachingToolCard>
                    </Spacer>
                  )}
                </For>
              </When>
            </BoardColumn>
          </BoardColumnWrapper>
        </Box>
      </PlanningBoardColumn>
    );
  }
);
