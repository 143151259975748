import { useCallback } from 'react';

import { CHALLENGE_LOCATIONS } from 'features/challenge/config';
import {
  usePlanningBacklogQueryActions,
  usePlanningDraftSprintsQueryActions,
} from 'features/planning/hooks';
import { PlanningDraggableItem } from 'features/planning/types';
import { useSprintsQueryActions } from 'features/sprint/hooks/query/sprints/useSprintsActions';

import {
  findIndexByProp,
  deleteItemMutative,
  insertItemMutative,
} from 'shared/utils/array';
import { throttle } from 'shared/utils/throttle';

export const useMoveBacklogChallenge = () => {
  const { getDraftSprints, updateDraftSprints, cancelDraftSprintsQueries } =
    usePlanningDraftSprintsQueryActions();
  const { getBacklog, updateBacklog, cancelBacklogQueries } =
    usePlanningBacklogQueryActions();
  const { getSprints, cancelSprintsQueries } = useSprintsQueryActions();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const moveItemInList = useCallback(
    throttle(
      async (
        draggedItem: PlanningDraggableItem,
        hoveredItem: PlanningDraggableItem
      ) => {
        draggedItem.toSprintId = undefined;

        await cancelBacklogQueries();

        const challenges = getBacklog();

        if (!challenges) {
          return;
        }

        const { items } = challenges;
        const { columnIndex: draggedColumnIndex } = draggedItem;
        const newItems = [...items];
        const hoveredChallengeIndex = findIndexByProp(
          items,
          'challengeId',
          hoveredItem?.id
        );
        let draggedChallengeIndex, draggedChallenge;

        draggedItem.index = hoveredChallengeIndex;

        switch (draggedItem.location) {
          case CHALLENGE_LOCATIONS.BACKLOG:
            draggedChallengeIndex = findIndexByProp(
              items,
              'challengeId',
              draggedItem.id
            );
            draggedChallenge = deleteItemMutative(
              newItems,
              draggedChallengeIndex
            );
            break;
          case CHALLENGE_LOCATIONS.DRAFT_SPRINT:
            await cancelDraftSprintsQueries();

            const draftSprints = getDraftSprints();

            if (draftSprints) {
              const { items: draftSprintItems } = draftSprints;
              const newDraftSprintItems = [...draftSprintItems];

              draggedChallengeIndex = findIndexByProp(
                draftSprintItems[draggedColumnIndex].challenges,
                'challengeId',
                draggedItem.id
              );
              draggedChallenge = deleteItemMutative(
                newDraftSprintItems[draggedColumnIndex].challenges,
                draggedChallengeIndex
              );

              updateDraftSprints(newDraftSprintItems);
            }
            break;
          case CHALLENGE_LOCATIONS.ACTIVE_SPRINT:
          case CHALLENGE_LOCATIONS.FINISHED_SPRINT:
            await cancelSprintsQueries();

            const sprints = getSprints();

            if (sprints) {
              const { items: sprintItems } = sprints;

              draggedChallengeIndex = findIndexByProp(
                sprintItems[draggedColumnIndex].challenges,
                'challengeId',
                draggedItem.id
              );
              draggedChallenge =
                sprintItems[draggedColumnIndex].challenges[
                  draggedChallengeIndex
                ];
            }
            break;
          default:
            throw new Error('Invalid location');
        }

        draggedItem.underChallengeId =
          hoveredChallengeIndex === 0 || !hoveredItem
            ? null
            : draggedChallengeIndex &&
              hoveredChallengeIndex > draggedChallengeIndex
            ? hoveredItem.id
            : hoveredItem.underChallengeId;
        draggedItem.columnIndex = -1;
        draggedItem.location = CHALLENGE_LOCATIONS.BACKLOG;

        if (draggedChallenge) {
          insertItemMutative(newItems, hoveredChallengeIndex, draggedChallenge);
        }

        updateBacklog(newItems);
      },
      500
    ),
    []
  );

  return {
    moveItemInList,
  };
};
