import { memo } from 'react';

import { COACHING_TOOLS_FEATURE_FLAG } from 'featureFlags';
import { useFeatureFlag } from 'featureFlags/hooks';
import Spacer from 'shared_DEPRECATED/components/Spacer';

import {
  CoachingToolCard,
  CoachingToolCardMenu,
  CoachingToolCardMenuCopyToSprint,
  CoachingToolCardMenuMoveToSprint,
  CoachingToolCardMenuRemoveButton,
} from 'features/coachingTool';
import {
  PlanningDraftSprintHeader,
  PlanningBoardItems,
  canItemBeDroppedInDropZone,
  IPlanningDraftSprint,
  PlanningBoardColumn,
  PlanningBoardColumnItemsCount,
} from 'features/planning';

import {
  BoardColumn,
  BoardColumnHeader,
  BoardColumnWrapper,
} from 'shared/components/Board';

export const PlanningDraftSprints = memo(
  ({ sprints }: { sprints: IPlanningDraftSprint[] }) => {
    const isCoachingToolsEnabled = useFeatureFlag(COACHING_TOOLS_FEATURE_FLAG);

    return (
      <>
        {sprints.map((sprint) => (
          <PlanningBoardColumn key={sprint.sprintId}>
            <BoardColumnWrapper fraction={sprints.length} key={sprint.sprintId}>
              <BoardColumnHeader key={sprint.sprintId} minHeight="3.75rem">
                <PlanningDraftSprintHeader sprint={sprint} />
              </BoardColumnHeader>
              <BoardColumn>
                {sprint.challenges.length > 0 && (
                  <PlanningBoardColumnItemsCount
                    name="challenge"
                    count={sprint.challenges.length}
                  />
                )}
                <PlanningBoardItems
                  challenges={sprint.challenges}
                  canDrop={canItemBeDroppedInDropZone(
                    'sprint',
                    sprint.sprintId
                  )}
                  sprint={sprint}
                />
                {isCoachingToolsEnabled && sprint.coachingTools.length > 0 && (
                  <>
                    <PlanningBoardColumnItemsCount
                      name="coaching tool"
                      count={sprint.coachingTools.length}
                    />
                    {sprint.coachingTools.map(
                      ({ name, description, picture, id }) => (
                        <Spacer size="sm" key={id}>
                          <CoachingToolCard
                            name={name}
                            description={description}
                            url={picture?.url}
                            id={id}
                          >
                            <CoachingToolCardMenu toolId={id}>
                              <CoachingToolCardMenuCopyToSprint toolId={id} />
                              <CoachingToolCardMenuMoveToSprint
                                toolId={id}
                                sprintId={sprint.sprintId}
                              />
                              <CoachingToolCardMenuRemoveButton
                                toolId={id}
                                sprintId={sprint.sprintId}
                              />
                            </CoachingToolCardMenu>
                          </CoachingToolCard>
                        </Spacer>
                      )
                    )}
                  </>
                )}
              </BoardColumn>
            </BoardColumnWrapper>
          </PlanningBoardColumn>
        ))}
      </>
    );
  }
);
