import { useCallback } from 'react';
import { useQueryClient } from 'react-query';

import { queryKeys } from 'api/config';

import { PlanningDraftSprint } from 'features/planning/DraftSprint';

export const usePlanningDraftSprintsQueryActions = () => {
  const queryClient = useQueryClient();

  const cancelDraftSprintsQueries = useCallback(async () => {
    await queryClient.cancelQueries({
      queryKey: [queryKeys.draftSprints],
    });
  }, [queryClient]);

  const getDraftSprints = useCallback(() => {
    return queryClient.getQueryData<{ items: PlanningDraftSprint[] }>([
      queryKeys.draftSprints,
    ]);
  }, [queryClient]);

  const updateDraftSprints = useCallback(
    (newItems: PlanningDraftSprint[]) => {
      queryClient.setQueryData<{ items: PlanningDraftSprint[] }>(
        [queryKeys.draftSprints],
        (old) => ({
          ...old,
          items: newItems,
        })
      );
    },
    [queryClient]
  );

  return {
    cancelDraftSprintsQueries,
    getDraftSprints,
    updateDraftSprints,
  };
};
